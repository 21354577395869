import $ from 'jquery'

$(document).ready(dhsv_vc_readmore_accordion())

function dhsv_vc_readmore_accordion() {
  $('.dhsv_vc_readmore_accordion').each(function () {
    const boxPositions = () => {
      const containerPosition = $(this).offset().left

      $('.js-modal', this).each(function () {
        $(this).css('left', -containerPosition)
      })
    }

    boxPositions()

    let resizeMaking
    $(window).resize(function () {
      clearTimeout(resizeMaking)
      resizeMaking = setTimeout(function () {
        boxPositions()
      }, 100)
    })

    $('.js-readmore', this).click(function () {
      if ($(window).width() > 767) {
        $('.js-readmore')
          .not($(this))
          .removeClass('is-active')
          .next('.js-modal')
          .hide()
      }
      $(this).toggleClass('is-active').next('.js-modal').slideToggle()
    })
  })
}
