import $ from 'jquery'

$(document).ready(dhsv_vc_supporters())

function dhsv_vc_supporters() {
  $('.dhsv_vc_supporters').each(function () {
    const equalHeight = () => {
      let highestBox = 0

      $('.js-title').height('auto')

      if ($('.js-title').length > 1) {
        $('.js-title', this).each(function () {
          if ($(this).height() > highestBox) {
            highestBox = $(this).height()
          }
        })

        $('.js-title', this).height(highestBox)
      }
    }

    equalHeight()

    let resizeMaking
    $(window).resize(() => {
      if ($(window).width() > 991) {
        clearTimeout(resizeMaking)
        resizeMaking = setTimeout(() => {
          equalHeight()
        }, 100)
      } else {
        $('.js-title').height('auto')
      }
    })
  })
}
