import cssVars from 'css-vars-ponyfill'
import ScrollOut from 'scroll-out'

const dhsvCommon = {
  stickyBanner() {
    let scrollTop
    const body = $('body')

    function handleScroll() {
      scrollTop = $(window).scrollTop()

      if (scrollTop <= 5) {
        body.removeClass('sticky-nav')
      } else {
        body.addClass('sticky-nav')
      }
    }

    if ($('#wpadminbar').length) {
      $(body).addClass('with-adminbar')
    }

    handleScroll()
    $(window).on('scroll', this.throttled(10, handleScroll))
  },
  throttled(delay, fn) {
    let lastCall = 0
    return function (...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  },
  resizeMaking(func) {
    let resizeMaking
    $(window).resize(function () {
      clearTimeout(resizeMaking)
      resizeMaking = setTimeout(function () {
        func()
      }, 100)
    })
  },
  setBodyWidth() {
    cssVars({
      variables: { '--body-width': document.body.clientWidth + 'px' },
    })
  },
  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 90
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  scrollOutInit() {
    ScrollOut({
      targets: '.scrollOut',
      threshold: 0.15,
      once: true,
    })
  },
  printFilename() {
    const inputFile = $('input[type="file"]')

    inputFile.closest('.gfield').addClass('file-upload')

    inputFile.change(function (e) {
      const fileName = e.target.files[0].name,
        file_size = e.target.files[0].size
      $(this).closest('.gfield').attr('data-filename', fileName)
      if (file_size > 50000000) {
        alert('File is too big, max size is 50Mb')
        $(this).val('')
        $(this).closest('.gfield').attr('data-filename', '')
      }
    })

    const labelNode = $('.file-upload .gfield_label')
    const uploadDescription = $('.file-upload .gfield_description').text()
    labelNode.contents().wrap('<span class="title" />')
    labelNode.append(`<span class="description">${uploadDescription}</span>`)
  },
  appendGformButton() {
    const gformButton = $('.gform_footer')
    gformButton.each(function () {
      $(this)
        .closest('.gform_wrapper')
        .find('.gform_fields')
        .append(
          '<li class="gfield col-md-6 gfield--submit">' +
            $(this).html() +
            '</li>'
        )
    })
  },
  appendHomeUrl() {
    if (!$('body.home').length) {
      const homeUrl = window.location.origin
      $('.menu-item a[href*="#"]').click(function () {
        if ($(this).attr('href') !== '#contact') {
          if (window.location.href.indexOf('/en/') > -1) {
            window.location = homeUrl + '/en/' + $(this).attr('href')
          } else {
            window.location = homeUrl + $(this).attr('href')
          }
        }
      })
    }
  },
  mobileMenu() {
    $('.hamburger').click(function () {
      $(this).toggleClass('is-active')
      $(this).next('.nav-primary').slideToggle()
    })
  },
  closeMenuOnElementClick() {
    const mobileNav = () => {
      if ($(window).width() < 768) {
        $('.nav-primary').addClass('mobile-nav').removeClass('desktop-nav')
      } else {
        $('.nav-primary').removeClass('mobile-nav').addClass('desktop-nav')
      }
    }

    mobileNav()
    this.resizeMaking(mobileNav)

    $('.mobile-nav .nav-link').click(function () {
      $('.hamburger').removeClass('is-active')
      $(this).closest('.nav-primary').slideUp()
    })
  },
  dropdownMenuElement() {
    const hoverDropdown = () => {
      if ($(window).width() > 767) {
        $('.desktop-nav .menu-item-has-children').on('mouseenter', function (
          e
        ) {
          e.preventDefault()
          var $this = $(this)
          if (!$this.hasClass('is-closing-animation')) {
            $this.addClass('is-active')
            $this.find('.dropdown-menu').slideDown(300)
          }
        })
        $('.desktop-nav .menu-item-has-children').on('mouseleave', function (
          e
        ) {
          e.preventDefault()
          var $this = $(this)
          $this.addClass('is-closing-animation')
          $this.removeClass('is-active')
          $this.find('.dropdown-menu').slideUp(300)
          setTimeout(function () {
            $this.removeClass('is-closing-animation')
          }, 2000)
        })
      } else {
        $('.menu-item-has-children').unbind('mouseenter mouseleave')
      }
    }

    hoverDropdown()
    this.resizeMaking(hoverDropdown)
  },
  init() {
    this.stickyBanner()
    this.setBodyWidth()
    this.scrollOutInit()
    // this.printFilename()
    // this.appendGformButton()
    this.appendHomeUrl()
    this.mobileMenu()
    this.closeMenuOnElementClick()
    this.dropdownMenuElement()

    $(document).on('gform_post_render', () => {
      this.printFilename()
      this.appendGformButton()
    })

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
