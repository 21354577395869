import $ from 'jquery'
import * as lottie from 'lottie-web'

$(window).on('load', startAnim)

function getAnimParam(el) {
  const screenHeight = document.documentElement.clientHeight
  const animHeight = $(el.wrapper).outerHeight()
  const animPos = $(el.wrapper).offset().top

  return { screenHeight, animHeight, animPos }
}

function initAnim(el) {
  const { screenHeight, animHeight, animPos } = getAnimParam(el)
  const scrollPos = document.documentElement.scrollTop
  if (
    animPos - screenHeight + animHeight * 1 <= scrollPos &&
    !$(el.wrapper).hasClass('animated')
  ) {
    $(el.wrapper).addClass('animated')
    el.playSegments([0, 140], true)
  }

  if (scrollPos > animPos) {
    $(el.wrapper).removeClass('animated')
  }

  if (scrollPos === 0) {
    $(el.wrapper).removeClass('animated')
    el.goToAndStop([0])
  }
}

function startAnim() {
  let animations = []

  $('.js-icon-animation').each(function (index, el) {
    animations.push(
      lottie.loadAnimation({
        container: el,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: $(el).data('animation'),
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      })
    )
  })

  animations.forEach(function (el) {
    // getAnimParam(el)
    initAnim(el)

    $(window).on('resize', initAnim(el))
    $(document).on('scroll', function () {
      initAnim(el)
    })
  })
}
